import { render } from "preact"
import { useEffect, useState } from "preact/hooks"
import Select from "react-select"

const { gettext, interpolate, projectData } = window // eslint-disable-line

export function renderProjectsBrowser(el) {
  render(<ProjectsBrowser />, el)
}

const SORT_BY = [
  {
    value: "date",
    label: gettext("Datum absteigend"),
    cmp: (a, b) => b.visible_from.date - a.visible_from.date,
  },
  {
    value: "rdate",
    label: gettext("Datum aufsteigend"),
    cmp: (a, b) => a.visible_from.date - b.visible_from.date,
  },
  {
    value: "title",
    label: gettext("Studientitel A-Z"),
    cmp: (a, b) => a.title < b.title,
  },
  {
    value: "client",
    label: gettext("Auftraggeber A-Z"),
    cmp: (a, b) => a.client < b.client,
  },
]
const PAGINATE_BY = 20

function applyFilters(projects, filters, query) {
  const _some = (projects, selected, field) => {
    const ids = selected.map(({ value }) => value)
    return projects.filter((project) =>
      ids.some((id) => project[field].includes(id)),
    )
  }
  const _every = (projects, selected, field) => {
    const ids = selected.map(({ value }) => value)
    return projects.filter((project) =>
      ids.every((id) => project[field].includes(id)),
    )
  }

  if (filters.topics.length) {
    projects = _some(projects, filters.topics, "topic")
  }
  if (filters.subtopics.length) {
    projects = _every(projects, filters.subtopics, "topic")
  }
  if (filters.services.length) {
    projects = _some(projects, filters.services, "service")
  }
  // if (filters.subservices.length) {
  //   projects = _every(projects, filters.subservices, "service")
  // }
  if (filters.persons.length) {
    projects = _some(projects, filters.persons, "persons")
  }
  if (query) {
    projects = projects.filter((project) => project._fts.includes(query))
  }
  return projects
}

function ProjectsBrowser() {
  const [projects, setProjects] = useState([])
  const [error, setError] = useState()

  useEffect(() => {
    fetch(projectData.url)
      .then((response) => response.json())
      .then((data) => {
        console.debug("data", data)
        setProjects(
          data.projects.map((project) => ({
            ...project,
            _fts: `${project.title} ${project.client} ${project.study_title}`.toLowerCase(),
          })),
        )
      })
      .catch(() => {
        setError(gettext("Fehler beim Laden der Daten."))
      })
  }, [])

  const [query, setQuery] = useState("")
  const [filters, setFilters] = useState(() => ({
    topics: [],
    subtopics: [],
    services: [],
    // subservices: [],
    persons: [],
    ...window.projectFilter,
  }))
  const [sortBy, setSortBy] = useState(SORT_BY[0])

  console.debug(JSON.stringify({ query, filters }))

  const filteredProjects = applyFilters(projects, filters, query.toLowerCase())
  filteredProjects.sort(sortBy.cmp)

  const [page, setPage] = useState(0)
  const paginatedFilteredProjects = filteredProjects.slice(
    PAGINATE_BY * page,
    PAGINATE_BY * (page + 1),
  )
  const numPages = 1 + Math.floor(filteredProjects.length / PAGINATE_BY)

  const topicIds = new Set(filters.topics.map(({ value }) => value))
  const subtopics = projectData.topics
    .filter((topic) => topicIds.has(topic.id) && topic.children)
    .flatMap((topic) => topic.children)

  // const sericeIds = new Set(filters.services.map(({ value }) => value))
  // const subservices = projectData.services
  //   .filter((service) => sericeIds.has(service.id) && service.children)
  //   .flatMap((service) => service.children)

  return (
    <div>
      <h1>{gettext("Projektarchiv")}</h1>
      <div className="row">
        <div className="columns medium-4 project-browser__header">
          <div class="__p-sticky">
            <div className="row">
              <div className="columns medium-12">
                <div className="text-search-box">
                  <h4 className="select-label search-label">
                    {gettext("Projekt suchen")}
                  </h4>
                  <input
                    type="search"
                    class="search-bar-input"
                    placeholder={gettext("Suche Titel/Auftraggeber")}
                    value={query}
                    onInput={(e) => {
                      setQuery(e.target.value)
                      setPage(0)
                    }}
                  />
                  <span
                    class={query ? "search-bar-clear" : "hide"}
                    onClick={() => {
                      setQuery("")
                      setPage(0)
                    }}
                  >
                    x
                  </span>
                </div>
                <hr />

                <div class="filter-box">
                  <h4 class="select-label topic-label">Filtere Themen</h4>
                  <Select
                    options={projectData.topics.map((o) => ({
                      value: o.id,
                      label: o.name,
                    }))}
                    isMulti
                    isSearchable={false}
                    placeholder={gettext("Auswählen…")}
                    value={filters.topics}
                    onChange={(topics) => {
                      setFilters({ ...filters, topics, subtopics: [] })
                      setPage(0)
                    }}
                  />
                  <h4 class="select-label topic-label">Filtere Unterthemen</h4>
                  <Select
                    options={subtopics.map((o) => ({
                      value: o.id,
                      label: o.name,
                    }))}
                    isMulti
                    isSearchable={false}
                    placeholder={gettext("Auswählen…")}
                    isDisabled={!subtopics.length}
                    value={filters.subtopics}
                    onChange={(subtopics) => {
                      setFilters({ ...filters, subtopics })
                      setPage(0)
                    }}
                  />
                  <h4 class="select-label topic-label">Filtere Leistungen</h4>
                  <Select
                    options={projectData.services.map((o) => ({
                      value: o.id,
                      label: o.name,
                    }))}
                    isMulti
                    isSearchable={false}
                    placeholder={gettext("Auswählen…")}
                    value={filters.services}
                    onChange={(services) => {
                      //setFilters({ ...filters, services, subservices: [] })
                      setFilters({ ...filters, services })
                      setPage(0)
                    }}
                  />
                  {/* <h4 class="select-label topic-label">
                    Filtere Unterleistungen
                  </h4>
                  <Select
                    options={subservices.map((o) => ({
                      value: o.id,
                      label: o.name,
                    }))}
                    isMulti
                    isSearchable={false}
                    placeholder={gettext("Auswählen…")}
                    isDisabled={!subservices.length}
                    value={filters.subservices}
                    onChange={(subservices) => {
                      setFilters({ ...filters, subservices })
                      setPage(0)
                    }}
                  /> */}
                  <h4 class="select-label topic-label">Filtere Personen</h4>
                  <Select
                    options={projectData.persons.map((o) => ({
                      value: o.id,
                      label: o.name,
                    }))}
                    isMulti
                    isSearchable={false}
                    placeholder={gettext("Auswählen…")}
                    value={filters.persons}
                    onChange={(persons) => {
                      setFilters({ ...filters, persons })
                      setPage(0)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns medium-8 project-browser__content">
          <div class="sort-box">
            <Select
              class="sort-select"
              options={SORT_BY}
              isSearchable={false}
              value={sortBy}
              onChange={setSortBy}
            />
          </div>
          <div class="result-list">
            {error ? (
              <h2>{error}</h2>
            ) : projects.length && paginatedFilteredProjects.length ? (
              <>
                {paginatedFilteredProjects.map((project) => (
                  <Project project={project} key={project.id} />
                ))}
                <div class="project-browser__pagination">
                  <button
                    class="button"
                    disabled={page <= 0}
                    onClick={() => setPage(page - 1)}
                  >
                    &lt;
                  </button>
                  {gettext("Page")} {page + 1} / {numPages}
                  <button
                    class="button"
                    disabled={page + 1 >= numPages}
                    onClick={() => setPage(page + 1)}
                  >
                    &gt;
                  </button>
                </div>
              </>
            ) : projects.length ? (
              <h2>{gettext("Keine Ergebnisse gefunden")}</h2>
            ) : (
              <h2>{gettext("Lade Projekte...")}</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function Project({ project }) {
  return (
    <a className="card card--project-archive shadowed" href={project.url}>
      {project.image ? (
        <div
          class="card-image"
          style={{ backgroundImage: `url(${project.image})` }}
        />
      ) : null}
      <span class="card-label">
        <date class="date">{project.visible_from.datestr}</date>
        {project.study_title ? (
          <h4>{project.study_title}</h4>
        ) : project._html_title ? (
          <h4 dangerouslySetInnerHTML={{ __html: project._html_title }} />
        ) : (
          <h4>{project.title}</h4>
        )}
        <span class="client">{project.client}</span>
      </span>
    </a>
  )
}
