/* global Foundation */

const $ = require("jquery")
window.$ = window.jQuery = $

import throttle from "lodash-es/throttle"
import { Navigation } from "./navigation"
import { renderProjectsBrowser } from "./projects.js"
import { renderSearch } from "./search.js"
import { initSearchBox } from "./searchBox.js"
import { initTracking } from "./tracking.js"
import { onReady } from "./utils.js"

//require('foundation-sites');
require("foundation-sites/js/foundation.core")
require("./foundation-sites-mod/foundation.util.triggers")
require("foundation-sites/js/foundation.util.mediaQuery")
require("foundation-sites/js/foundation.util.keyboard")
require("foundation-sites/js/foundation.util.box")
require("foundation-sites/js/foundation.util.nest")
require("foundation-sites/js/foundation.responsiveToggle")
require("foundation-sites/js/foundation.sticky")
require("flexslider/jquery.flexslider-min")
require("featherlight/release/featherlight.min.js")
require("shariff/dist/shariff.complete.js")

onReady(initSearchBox)
onReady(initTracking)

onReady(() => {
  const el = document.getElementById("project-browser")
  if (el) {
    renderProjectsBrowser(el)
  }
})

onReady(() => {
  // Mobile Nav Toggle
  new Navigation()

  const cte = document.querySelectorAll("[data-cse-cx]")
  if (cte) {
    cte.forEach((el) => renderSearch(el))
  }
  const scrolledThreshold =
    document.querySelector(".sticky-header").offsetHeight - 25
  let lastPageYOffset = 0

  window.addEventListener(
    "scroll",
    throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
        if (window.pageYOffset > lastPageYOffset) {
          document.body.classList.remove("scrolled--up")
          document.body.classList.add("scrolled--down")
        } else {
          document.body.classList.add("scrolled--up")
          document.body.classList.remove("scrolled--down")
        }
      } else {
        document.body.classList.remove("scrolled")
        document.body.classList.remove("scrolled--up")
        document.body.classList.remove("scrolled--down")
      }

      lastPageYOffset = window.pageYOffset
    }, 100),
    { passive: true },
  )
})

/**
 * Project Browser App
 *
// init the injector
// let { SearchBox } = require("./searchBox")
// require('expose-loader?__Injector!./injector')
// let {Injector} = require('./injector')
// window.__Injector = Injector;



//let smoothScroll = require('smoothscroll');
// let _ = require('lodash');
var App = function () {
  //render will be called from the injector
  let _render = function () {
    let projectApp = document.getElementById("project-browser")
    if (projectApp !== null) {
      require.ensure([], (require) => {
        require("./components/app")
      })
    }
    //IMPORTANT CALL THIS ONLY FROM THE PAGE BOTTOM, EVEN IN DEBUG
    // Foundation and Back to top
    $(document).foundation()
    //$('.back-to-top').click(() => smoothScroll(0, 0));

    //shariff, if available
    let shariffObject = $(".shariff")
    if (shariffObject.length > 0) {
      new Shariff(shariffObject, {
        orientation: "horizontal",
      })
    }

    //page wide inits
    $(() => {
      //Expanding search box
      SearchBox.init()
      Tracking.init()
    })

  }

  //expose the render method
  return {
    render: _render,
  }
}.call(App || {})

//Register the App with the injector. Only the Injector will call render.
window.__Injector.registerApp(App)
// module.exports.App = App
*/
//

onReady(() => {
  // Extend mobile navigation with meta and language nav
  const meta_nav = $("footer .menu").clone().attr("class", "menu vertical meta")
  const lang_nav = $(".lang-nav").clone().attr("class", "lang-nav")
  $(".main-nav").append(meta_nav)
  $(".main-nav").append(lang_nav)
})

onReady(() => {
  const flexSliderSizes = {
    default: 3,
    large: 3,
    medium: 2,
    small: 1,
  }
  //Flexslider
  if ($(".flexslider").length > 0) {
    //flexslider
    $(".flexslider").flexslider({
      animation: "slide",
      animationLoop: true,
      itemWidth: 320,
      itemMargin: 20,
      minItems: flexSliderSizes[Foundation.MediaQuery.current],
      maxItems: flexSliderSizes[Foundation.MediaQuery.current],
    })

    //hook up flexslider to mediaqueries
    const flexSliderObject = $(".flexslider").data("flexslider")
    $(window).on("changed.zf.mediaquery", (_event, newSize) => {
      flexSliderObject.vars.minItems = flexSliderSizes[newSize]
      flexSliderObject.vars.maxItems = flexSliderSizes[newSize]
    })
  }
})
